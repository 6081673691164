import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import {
  coverImage,
  container,
  content
} from "./page.module.css"

export const pageQuery = graphql`
  query PathPostBySlug(
    $id: String
  ) {
    site {
      siteMetadata {
          title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
          childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, transformOptions: { fit: INSIDE })
            }
        }
      }
    }
  }
`

export default function IndexPage({ data }) {
  const page = data.markdownRemark
  const image = getImage(page.frontmatter.image)

  return (
    <Layout>
      <Seo
        title={page.frontmatter.title}
        // description={post.frontmatter.description || post.excerpt}
      />
      <article className={container}>
        { image ? (
          <div className={coverImage}>
            <GatsbyImage
              objectFit="contain"
              loading={"lazy"}
              alt={page.frontmatter.title}
              image={image}
            />
          </div>
          ) : '' }
        <div className={content}>
          <h1>{page.frontmatter.title}</h1>
          {page.html.length ? (
            <section dangerouslySetInnerHTML={{ __html: page.html }}></section>
          ) : '' }
        </div>
      </article>
    </Layout>
  )
}
